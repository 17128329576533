import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import DotsDesign from "../../assets/images/svg/dots-design.svg"
import MamopayCheckout from "./MamopayCheckout";
import ModalPaymentFailed from "./ModalPaymentFailed";
import ModalPaymentSuccess from "./ModalPaymentSuccess";

const OrderStatus = () => {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null)
  const [showTooltip, setShowTooltip] = useState(false);
  const [mamopayLink, setMamopayLink] = useState(null);
  const [openMamopay, setOpenMamopay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusMamopay, setStatusMamopay] = useState(null);

  const intervalPaymentRef = useRef(null);
  const intervalProcessRef = useRef(null);

  const status = {
    pending: 'Waiting for order confirmation',
    waiting_for_payment: 'Waiting for payment',
    processed: 'Order is confirmed and processed',
    delivered: 'Order is on delivery',
    completed: 'Order is completed',
    cancelled: 'Order is cancelled'
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    setStatusMamopay(status);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${window.API_HOST}/v1/orders/${orderId}`);
        const result = await response.json();
        if (result.error) {
          window.location.href = window.location.origin + `/order-status/check`;
        }
        setOrderData(result?.data);
        setMamopayLink(result?.data.payment_link);
        setLoading(false);
        const queryParams = new URLSearchParams(window.location.search);
        const status = queryParams.get('status');
        if (result?.data?.status === 'processed' && result?.data?.payment_method === 'payment_link' && (result?.data?.payment_status === 'pending' || result?.data?.payment_status === 'failed') && status === null) {
          setOpenMamopay(true)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        window.location.href = window.location.origin + `/order-status/check`;
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!loading) {
      intervalPaymentRef.current = setInterval(fetchOrderData, 5000);
      return () => clearInterval(intervalPaymentRef.current);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      intervalProcessRef.current = setInterval(fetchOrderData, 60000);
      return () => clearInterval(intervalProcessRef.current);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      if (statusMamopay === 'failed') {
        document.getElementById('submit-payment-failed').style.display = 'block';
        document.getElementById('submit-payment-failed').style.opacity = 1;
        document.getElementById('backdrop-payment-failed').style.display = 'block';
      } else if (statusMamopay === 'captured') {
        document.getElementById('submit-payment-success').style.display = 'block';
        document.getElementById('submit-payment-success').style.opacity = 1;
        document.getElementById('backdrop-payment-success').style.display = 'block';
      }
    }
  }, [statusMamopay, loading]);

  const fetchOrderData = async () => {
    try {
      const response = await fetch(`${window.API_HOST}/v1/orders/${orderId}`);
      const result = await response.json();
      if (result.error) {
        throw new Error('Error fetching data');
      }
      setOrderData(result?.data);
      setMamopayLink(result?.data.payment_link);
      if (result?.data?.status === 'completed' || result?.data?.status === 'cancelled') {
        clearInterval(intervalProcessRef.current);
      }

      if (result?.data?.status === 'processed' && result?.data?.payment_method === 'payment_link' && (result?.data?.payment_status === 'pending' || result?.data?.payment_status === 'failed') && statusMamopay === null) {
        setOpenMamopay(true)
        clearInterval(intervalPaymentRef.current);
      }
      
      if (result?.data?.payment_method === 'cash') {
        clearInterval(intervalPaymentRef.current);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const comaSeparatedSubMenu = (subMenu) => {
    let allSubMenu = []
    subMenu.forEach(sub => {
      if (sub.quantity > 0) {
        allSubMenu.push(`(${sub.quantity}) ${sub.title}`)
      }
    })
    return allSubMenu.join(', ')
  }

  const copyOrderId = () => {
    navigator.clipboard.writeText(orderId)
      .then(() => {
        console.log('Text copied to clipboard:', orderId);
      })
      .catch((error) => {
        console.error('Error copying text:', error);
      });
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 4000);
  }

  const parseDateHours = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <a href="/">
              <i className="ri-arrow-left-s-line"></i>
            </a>
            <h2>Order Tracking</h2>
          </div>
        </div>
      </header>

      <section>
        <div className="custom-container">
          <h5 className="dark-text">
            <span className="light-text">Order ID : </span>{orderId}
            <span style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => copyOrderId()}>
              {showTooltip && (
                <span style={{
                  background: '#ECECEC',
                  position: 'absolute',
                  padding: '8px',
                  borderRadius: '4px',
                  transform: 'translateX(-35%)',
                  zIndex: 1,
                  top: '40px'
                }}>
                  Copied
                  <div
                    style={{
                      position: 'absolute',
                      width: 0,
                      height: 0,
                      borderLeft: '6px solid transparent',
                      borderRight: '6px solid transparent',
                      borderTop: '6px solid #ECECEC',
                      top: '100%',
                      left: '50%',
                      transform: 'translateX(-35%)'
                    }}
                  ></div>
                </span>
              )}
              <i
                className="ri-file-copy-line"
                style={{ marginRight: '4px' }}
                onClick={() => copyOrderId()}
              ></i>
              Copy Order ID
            </span>
          </h5>
          <div className="order-tracking mt-3" style={{ padding: '24px' }}>
            <ul className="tracking-place">
              {
                (orderData?.status === 'pending' || orderData?.submitted_at) && (
                  <li className="active" style={{ marginBottom: '8px' }}>
                    <h6 style={{ width: '60px' }}>{parseDateHours(orderData?.submitted_at)}</h6>
                    <span></span>
                    <h6>{status.pending}</h6>
                  </li>
                )
              }
              {
                orderData?.status === 'cancelled' && orderData?.cancelled_at ? (
                  <li className="active p-0" style={{ marginBottom: '8px' }}>
                    <h6 style={{ width: '60px' }}>{parseDateHours(orderData?.cancelled_at)}</h6>
                    <span></span>
                    <h6>{status.cancelled}</h6>
                  </li>
                ) : (
                  <>
                    { (orderData?.status === 'processed' || orderData?.processed_at) && (
                      <li className="active" style={{ marginBottom: '8px' }}>
                        <h6 style={{ width: '60px' }}>{parseDateHours(orderData?.processed_at)}</h6>
                        <span></span>
                        { orderData.payment_method === 'cash' ? (<h6>{status.processed}</h6>) : (
                          <div>
                            <h6>{status.processed}</h6>
                            <h6 className="fst-italic mt-2">
                                Payment Link:
                                { orderData?.payment_status === 'paid' ? (
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      marginLeft: '2px',
                                      fontWeight: 'bold',
                                      color: '#000000',
                                    }}
                                  >[Status: Paid]</div>
                                ) : (
                                  <button
                                    style={{
                                      border: 'none',
                                      background: 'transparent',
                                      color: '#56A5B0',
                                      fontStyle: 'italic',
                                      textDecoration: 'underline'
                                    }}
                                    onClick={() => { setOpenMamopay(true) }}
                                    >
                                    [Link via Mamopay]
                                  </button>
                                )}
                            </h6>
                          </div>
                        )}
                      </li>
                    )}
                    { (orderData?.status === 'delivered' || orderData?.delivered_at) && (
                      <li className="active" style={{ marginBottom: '8px' }}>
                        <h6 style={{ width: '60px' }}>{parseDateHours(orderData?.delivered_at)}</h6>
                        <span></span>
                        <h6>{status.delivered}</h6>
                      </li>
                    )}
                    { (orderData?.status === 'completed' || orderData?.completed_at) && (
                      <li className="active p-0" style={{ marginBottom: '8px' }}>
                        <h6 style={{ width: '60px' }}>{parseDateHours(orderData?.completed_at)}</h6>
                        <span></span>
                        <h6>{status.completed}</h6>
                      </li>
                    )}
                  </>
                )
              }
            </ul>
          </div>
        </div>
      </section>

      <section>
        <div className="custom-container">
          <h3 className="fw-semibold dark-text">Food Order</h3>
          { orderData && orderData.items.length > 0 && orderData.items.map((menu, menuIdx) => (
            <div key={`menu-order-${menu.menu_id}-${menuIdx}`} className="horizontal-product-box mt-3">
              <div className="product-content row" style={{ display: 'flex', padding: '8px', margin: '0px', width: '100%' }}>
                <div className="col-10" style={{ padding: '0px' }}>
                  <h5>{menu.title}</h5>
                  <h6>{comaSeparatedSubMenu(menu.sub_menu)}</h6>
                </div>
                <div className="col-2" style={{ alignSelf: 'center', margin: '0px', padding: '0px' }}>
                  <h3 className="product-price" style={{ position: 'relative', display: 'flex', justifyContent: 'right', bottom: '0px' }}>
                    AED {menu.subtotal}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="bill-details section-b-space">
        <div className="custom-container">
          <h3 className="fw-semibold dark-text">Bill Details</h3>
          <div className="total-detail mt-3">
            <div className="sub-total mb-3">
              <h5>Sub Total</h5>
              <h5 className="fw-semibold">AED {orderData?.item_subtotal}</h5>
            </div>
            { orderData?.tax_amount > 0 && (
              <div className="sub-total mb-3">
                <h5>VAT ({orderData?.tax_percentage}{orderData?.tax_included ? ' included' : ''})</h5>
                <h5 className="fw-semibold">AED {orderData?.tax_amount}</h5>
              </div>
            )}
            { orderData?.order_type === 'delivery' && (
              <div className="sub-total mb-3">
                <h5>Delivery Charge</h5>
                <h5 style={{ color: '#FF9933' }}>AED {orderData?.delivery_fee}</h5>
              </div>
            )}
            <div className="grand-total mb-3">
              <h5 className="fw-semibold">Grand Total</h5>
              <h5 className="fw-semibold amount">AED {orderData?.payment_amount}</h5>
            </div>
            <img
              className="dots-design"
              alt="dots"
              src={DotsDesign}
            ></img>
          </div>
        </div>
      </section>

      { openMamopay && (
        <MamopayCheckout paymentLink={mamopayLink} orderId={orderId} />
      )}

      <ModalPaymentFailed orderId={orderId} />

      <ModalPaymentSuccess orderId={orderId} />
    </React.Fragment>
  )
}

export default OrderStatus;
