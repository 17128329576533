import React, { useState } from "react";
import LogoRempah from "../../assets/images/logo/rempah.png"

import Navigation from "./Navigation";

const OrderSearch = () => {
  const [orderId, setOrderId] = useState('')
  const [isNotFound, setIsNotFound] = useState(false)

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value)
  }

  const handleCheckOrder = async () => {
    setIsNotFound(false)
    try {
      const response = await fetch(`${window.API_HOST}/v1/orders/${orderId}`);
      const result = await response.json();
      if (result.error) {
        if (result.error === 'Order not found') setIsNotFound(true)
      }
      if (result?.data) {
        window.location.href = window.location.origin + `/order-status/${orderId}`;
      }
    } catch (error) {
      console.error('Error get delivery fee:', error);
    }
  }

  return (
    <React.Fragment>
      <section>
        <div className="custom-container logo-container mt-5 mb-5">
          <img className="banner-img img-fluid w-50" alt="logo" src={LogoRempah}></img>
          <div className="mt-2 fw-semibold">Check My Order Status</div>
        </div>
      </section>

      <div className="custom-container" style={{ width: '100%', maxWidth: '600px' }}>
        <form className="auth-form mt-4" target="_blank">
          <div className="form-group">
            <label className="form-label fw-semibold">
              Order ID { isNotFound && (<span className="fw-normal fst-italic" style={{ color: '#CC0000' }}>  Order ID is not found</span>) }
            </label>
            <div className="form-input mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Enter order ID"
                value={orderId}
                onChange={(e) => handleOrderIdChange(e)}
              />
            </div>
          </div>
        </form>

        <div
          className="filter-footer"
        >
          <button
            className="btn theme-btn apply-btn w-100"
            style={{
              cursor: orderId === '' ? 'not-allowed' : 'auto',
              pointerEvents: orderId === '' ? 'auto' : 'auto',
              opacity: orderId === '' ? 0.5 : 1
            }}
            onClick={() => handleCheckOrder()}
            disabled={orderId === ''}
          >
            Check Order
          </button>
        </div>
      </div>

      <section className="panel-space"></section>
      <Navigation />
    </React.Fragment>
  )
}

export default OrderSearch;
