import React, { useEffect } from 'react';

const MamopayCheckout = ({ paymentLink, orderId }) => {
  useEffect(() => {
    // Load the Mamopay checkout script
    const script = document.createElement('script');
    script.src = 'https://assets.mamopay.com/public/checkout-inline.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="modal-alert-backdrop" />
      <div className="modal-alert">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                id="mamo-checkout-container"
              >
                <iframe
                  id="mamo-checkout"
                  src={paymentLink}
                  title="Mamopay Checkout"
                  style={{ width: '100%', height: '500px', border: 'none' }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MamopayCheckout;
