import React from "react";

import SuccessLightIcon from '../../assets/images/gif/success-payment-light.gif'

const backdropStyles = {
  display: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

const ModalPaymentSuccess = ({ orderId }) => {
  return (
    <div>
      <div
        id="backdrop-payment-success"
        style={backdropStyles}
      />
      <div className="modal fade success-modal" id="submit-payment-success" tabIndex="-1" style={{ display: 'none', opacity: 1 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="confirm-title" style={{ textAlign: 'center', padding: '16px 40px 0px 40px' }}>
                <img className="img-fluid confirm-offer for-light" src={SuccessLightIcon} alt="success-icon" />
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '18px'
                  }}
                >
                  Payment Success
                </div>
              </div>

              <div
                className="filter-footer mb-3"
              >
                <button
                  className="btn theme-btn apply-btn w-100"
                  onClick={() => {
                    window.location.href = window.location.origin + `/order-status/${orderId}`;
                    document.getElementById('submit-payment-success').style.display = 'none';
                    document.getElementById('backdrop-payment-success').style.display = 'none';
                  }}
                >
                  Track Order Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPaymentSuccess;
