import React from "react";

import ErrorIcon from '../../assets/images/error_rounded.png'

const backdropStyles = {
  display: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

const ModalPaymentFailed = ({ orderId }) => {
  return (
    <div>
      <div
        id="backdrop-payment-failed"
        style={backdropStyles}
      />
      <div className="modal fade success-modal" id="submit-payment-failed" tabIndex="-1" style={{ display: 'none', opacity: 1 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="confirm-title" style={{ textAlign: 'center', padding: '56px 40px 0px 40px' }}>
                <img className="img-fluid confirm-offer w-50" src={ErrorIcon} alt="error-address" />
                <div
                  className="mt-5"
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '18px'
                  }}
                >
                  Payment Failed
                </div>
              </div>

              <div
                className="filter-footer mb-3"
              >
                <button
                  className="btn theme-btn apply-btn w-100"
                  onClick={() => {
                    window.location.href = window.location.origin + `/order-status/${orderId}`;
                    document.getElementById('submit-payment-failed').style.display = 'none';
                    document.getElementById('backdrop-payment-failed').style.display = 'none';
                  }}
                >
                  Retry Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPaymentFailed;
