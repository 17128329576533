import React from "react"

import LogoRempahFill from "../../assets/images/logo/rempah.png"
import LogoRempah from "../../assets/images/logo/rempah-bw.png"
import FoodIcon from "../../assets/images/svg/food.svg"
import FoodFillIcon from "../../assets/images/svg/food-fill.svg"
import OrderStatusIcon from "../../assets/images/svg/grocery.svg"
import OrderStatusFillIcon from "../../assets/images/svg/grocery-fill.svg"
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <React.Fragment>
      <div className="navbar-menu">
          <ul>
              <li className={window.location.pathname === '/' ? 'active' : ''} style={{ width: 'fit-content' }}>
                  <Link to="/" className="icon logo" style={{ display: 'grid' }}>
                      <img className="img-fluid unactive" alt="logo" src={LogoRempah}></img>
                      <img className="img-fluid active" alt="logo" src={LogoRempahFill}></img>
                      <span>Home</span>
                  </Link>
              </li>
              <li className={window.location.pathname === '/menu' ? 'active' : ''} style={{ width: 'fit-content' }}>
                  <Link to="/menu" className="icon" style={{ display: 'grid' }}>
                      <img className="unactive" alt="food" src={FoodIcon}></img>
                      <img className="active" alt="food" src={FoodFillIcon}></img>
                      <span>Order Food</span>
                  </Link>
              </li>
              <li className={window.location.pathname === '/order-status/check' ? 'active' : ''} style={{ width: 'fit-content' }}>
                <Link to="/order-status/check" className="icon" style={{ display: 'grid' }}>
                  <img className="unactive" alt="status" src={OrderStatusIcon}></img>
                  <img className="active" alt="status" src={OrderStatusFillIcon}></img>
                  <span>Order Status</span>
                </Link>
              </li>

            </ul>
      </div>
    </React.Fragment>
  )
}

export default Navigation
