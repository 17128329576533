import React, {useState, useEffect} from "react";
import { requestForToken } from '../firebase';

const ModalNotificationPrompt = ({ notificationAllowed, callback }) => {
  const [hideModal, setHideModal] = useState(notificationAllowed);

  useEffect(() => {
    setHideModal(notificationAllowed);
  }, [notificationAllowed]);

  const requestPermissionNotifAgain = async () => {
    document.getElementById('enable-notification').disabled = true;
    await Notification.requestPermission();
    console.log('Notification', Notification.permission);
    if (Notification.permission === 'granted') {
      const notifToken = await requestForToken();
      if (callback) {
        callback(notifToken);
      }
    }
    document.getElementById('submit-notification').style.display = 'none';
    document.getElementById('backdrop-notification').style.display = 'none';
    document.getElementById('enable-notification').disabled = false;
  };

  const backdropStyles = {
    display: hideModal ? 'none' : 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  };

  const submitNotifStyles = {
    display: hideModal ? 'none' : 'block',
    opacity: 1
  };

  return (
    <div>
      <div
        id="backdrop-notification"
        style={backdropStyles}
      />
      <div className="modal fade success-modal" id="submit-notification" tabIndex="-1" style={submitNotifStyles}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="confirm-title" style={{ textAlign: 'center', padding: '10px 40px 0px 40px' }}>
                <div
                  style={{
                    textAlign: 'center',
                    padding: '10px',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                  Stay Updated on Your Orders! 🍔📲<br/>
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    padding: '10px',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                  To receive real-time update on your orders, please enable notifications for optimal ordering experience.
                </div>
              </div>
              <div
                className="filter-footer mb-3"
              >
                <button
                  id="enable-notification"
                  className="btn theme-btn apply-btn w-100"
                  onClick={() => requestPermissionNotifAgain()}
                >
                  Enable Notifications
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNotificationPrompt;
