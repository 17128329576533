import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import CustomFoodAvailablity from "./CustomFoodAvailability";

const UpdateShowMenu = () => {
  const { id } = useParams();
  const [sessionToken, setSessionToken] = useState('');

  const [category, setCategory] = useState('');
  const [menu, setMenu] = useState([]);

  const [detailFood, setDetailFood] = useState({})
  const [subMenu, setSubMenu] = useState([])

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('session_auth');
    if (!isLoggedIn) {
      window.location.href = window.location.origin + `/admin/login`;
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const storedData = localStorage.getItem('session_auth');
      try {
        if (storedData) {
          setSessionToken(storedData)
          const response = await fetch(`${window.API_HOST}/admin/menu-categories`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedData}`
            }
          });
          const result = await response.json();
          const title = result.data.filter(item => {
            return item.id === parseInt(id)
          })
          if (title.length > 0) setCategory(title[0].title)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('session_auth');
    const fetchMenu = async () => {
      try {
        if (storedData) {
          setSessionToken(storedData)
          const response = await fetch(`${window.API_HOST}/admin/menu-categories/${id}/menu`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedData}`
            }
          });
          const result = await response.json();
          setMenu(result?.data);
        }
      } catch (error) {
        console.error('Error fetching menu:', error);
      }
    };

    fetchMenu();
  }, [id]);

  const handleMenuAvailability = async (menu, idx) => {
    try {
      const response = await fetch(`${window.API_HOST}/admin/menu-categories/${id}/menu/${menu.id}?available=${!menu.available}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to update data');
      }
      const result = await response.json();
      setMenu((prev) => {
        const updatedMenu = [...prev]
        updatedMenu[idx].available = result?.data.available
        return updatedMenu
      })
    } catch (error) {
      console.error('Error update status menu:', error);
    }
  };

  const handleSetAddOnAvailability = async (data) => {
    try {
      const response = await fetch(`${window.API_HOST}/admin/menu-categories/${id}/menu//${data.id}/sub-menu`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });
      const result = await response.json();
      setDetailFood(data)
      setSubMenu(result.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  if (!menu) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/menus">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>{category}</h2>
          </div>
        </div>
      </header>

      <section>
        <div className="custom-container">
          <div className="accordion-body">
            {menu.map((item, menuIdx) => (
              <div
                key={item.id}
                className="product-box2 mt-4"
                style={{ opacity: 1 }}
              >
                <div className="product-content">
                  <h5
                    className="product-name"
                    data-bs-target="#product-availability"
                    data-bs-toggle="modal"
                    style={{ cursor: 'pointer' }}
                    onClick={async () => await handleSetAddOnAvailability(item)}
                  >
                    {item.title}
                  </h5>
                  <div className="product-price">
                    <h6 className="fw-semibold">
                      <span>{item.currency} {item.price}</span>
                    </h6>
                  </div>
                  <p className="mb-0 mt-2 pt-2">{item.description}</p>
                </div>
                <div className="product-img" style={{ width: '152px', height: '92px' }}>
                  <img
                    src={item.cover_image}
                    className="img-fluid img"
                    alt={item.title}
                    data-bs-target="#product-availability"
                    data-bs-toggle="modal"
                    style={{ cursor: 'pointer' }}
                    onClick={async () => await handleSetAddOnAvailability(item)}
                  />
                  <div
                    className="add-btn"
                  >
                    <div className='notification-setting p-2'>
                      <li>
                        <div className="notification p-0">
                          <div className="switch-btn" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <input type="checkbox" checked={item.available} onChange={() => handleMenuAvailability(item, menuIdx)} />
                            <h6 className="dark-text" style={{ textWrap: 'nowrap' }}>
                              {item.available ? 'Available' : 'Not Available'}
                            </h6>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="panel-space"></section>

      <CustomFoodAvailablity
        detail={detailFood}
        subMenu={subMenu}
      />
    </>
  );
};

export default UpdateShowMenu;
