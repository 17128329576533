import React, {useState, useEffect} from "react";

const CustomFoodAvailablity = ({ detail, subMenu }) => {
  const [sessionToken, setSessionToken] = useState('');
  const [dataSubMenu, setDataSubMenu] = useState([]);
  const { id, category_id, title, description, price, cover_image } = detail;

  useEffect(() => {
    const storedData = localStorage.getItem('session_auth');
    if (storedData) {
      setSessionToken(storedData)
    }
    setDataSubMenu(subMenu);
  }, [subMenu]);

  const handleSubMenuAvailability = async (menuIdx, option, optionIdx) => {
    try {
      const response = await fetch(`${window.API_HOST}/admin/menu-categories/${category_id}/menu/${id}/sub-menu/${option.id}?available=${!option.available}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to update data');
      }
      setDataSubMenu((prev) => {
        const updatedSubMenu = [...prev]
        updatedSubMenu[menuIdx].options[optionIdx].available = !option.available
        return updatedSubMenu
      });
    } catch (error) {
      console.error('Error update status add on:', error);
    }
  };

  return (
    <div className="modal filter-modal" id="product-availability" tabIndex="-1" style={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}>
      <div className="modal-dialog modal-fullscreen" style={{ width: '100%' }}>
        <div className="modal-content">
          <div className="custom-container">
            <div className="filter-header section-t-space">
              <h1 className="title">Custom Food Availability</h1>
              <a href="#" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
            </div>
            <div className="filter-body section-t-space">
              <div className="horizontal-product-box">
                <div className="product-img">
                  <img
                    src={cover_image}
                    className="img-fluid img"
                    style={{ width: 'auto', height: '98px' }}
                    alt="image-food"
                  />
                </div>
                <div className="product-content row" style={{ display: 'flex', padding: '8px 16px 8px 8px' }}>
                  <div className="col-10">
                    <h5>{title}</h5>
                    <h6>{description}</h6>
                  </div>
                  <div className="col" style={{ alignSelf: 'center', margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <h3 className="product-price" style={{ position: 'relative', display: 'flex', justifyContent: 'right', bottom: '0px' }}>
                      AED {parseFloat(price).toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>

              {dataSubMenu && dataSubMenu.map((menu, menuIdx) => (
                <div key={`menu-${menu.id}`} className="filter-box section-t-space">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 className="fw-semibold dark-text">
                      {menu.title}
                    </h3>
                    <h3 className="fw-semibold dark-text">
                      Availability
                    </h3>
                  </div>

                  <div className="filter-list my-3">
                    { menu.options.map((option, optionIdx) => (
                        <div
                          key={`menu-option${option.id}`}
                          style={{ opacity: 1, display: 'flex', marginBottom: '16px', alignItems: 'center' }}
                        >
                          <h5 className="product-size">{option.title}</h5>
                          <div className="product-price" style={{ marginLeft: 'auto', display: 'flex' }}>
                            <div className='notification-setting remove-box'>
                              <li className="m-0">
                                <div className="notification p-0">
                                  <div className="switch-btn" style={{ display: 'flex' }}>
                                    <input type="checkbox" checked={option.available} onChange={() => handleSubMenuAvailability(menuIdx, option, optionIdx)} />
                                  </div>
                                </div>
                              </li>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomFoodAvailablity;
