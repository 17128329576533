import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const OrderList = () => {
  const [activeTab, setActiveTab] = useState('Order In');
  const [orderList, setOrderList] = useState([])
  const [filteredOrder, setFilteredOrder] = useState([])

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('session_auth');
    if (!isLoggedIn) {
      window.location.href = window.location.origin + `/admin/login`;
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const storedData = localStorage.getItem('session_auth');
      try {
        if (storedData) {
          const response = await fetch(`${window.API_HOST}/admin/orders?status[]=pending&status[]=processed&status[]=delivered&status[]=completed&status[]=cancelled`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedData}`
            }
          });
          const result = await response.json();
          setOrderList(result.data);
          setFilteredOrder(result.data.filter(order => order.status === 'pending'))
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredOrder(ordersByStatus());
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const ordersByStatus = () => {
    if (orderList) {
      if (activeTab === 'Order In') {
        return orderList.filter(order => order.status === 'pending')
      } else if (activeTab === 'Prepared') {
        return orderList.filter(order => order.status === 'processed')
      } else if (activeTab === 'Delivered') {
        return orderList.filter(order => order.status === 'delivered' || order.status === 'completed')
      } else if (activeTab === 'Rejected') {
        return orderList.filter(order => order.status === 'cancelled')
      }
    }
    return []
  }

  if (!orderList) {
    return <p>Loading...</p>;
  }

  return (
    <React.Fragment>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Order List</h2>
          </div>
        </div>
      </header>

      <section>
        <div className="custom-container">
          <div className="tabs-container">
            <div className={`tab ${activeTab === 'Order In' ? 'active-tab' : ''}`} onClick={() => handleTabClick('Order In')}>
              Order In
            </div>
            <div className={`tab ${activeTab === 'Prepared' ? 'active-tab' : ''}`} onClick={() => handleTabClick('Prepared')}>
              Prepared
            </div>
            <div className={`tab ${activeTab === 'Delivered' ? 'active-tab' : ''}`} onClick={() => handleTabClick('Delivered')}>
              Delivered
            </div>
            <div className={`tab ${activeTab === 'Rejected' ? 'active-tab' : ''}`} onClick={() => handleTabClick('Rejected')}>
              Rejected
            </div>
          </div>
          <div className='pb-4'>
            { filteredOrder && filteredOrder.map((order) => (
              <Link to={`/admin/orders/${order.order_number}`} key={order.order_number}>
                <div className="tab-content mt-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <h4 className='fw-semibold dark-text'>
                      Order by:<span className='fw-normal'> {order.buyer.name}</span>
                    </h4>
                    <h4 className='fw-semibold dark-text'>
                      Order Type:<span className='fw-normal'> {order.order_type.charAt(0).toUpperCase() + order.order_type.slice(1).toLowerCase()}</span>
                    </h4>
                    <h4 className='fw-semibold dark-text'>
                      Payment Method:<span className='fw-normal'> {order.payment_method === 'cash' ? 'Cash' : 'Link via Mamopay'}</span>
                    </h4>
                  </div>
                  <div>
                    <h4 className='fw-semibold dark-text' style={{ color: '#FF8d2F' }}>
                      Check Detail
                    </h4>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default OrderList;
