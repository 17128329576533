import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: window.FIREBASE_MESSAGING_API_KEY,
  authDomain: window.FIREBASE_MESSAGING_AUTH_DOMAIN,
  projectId: window.FIREBASE_MESSAGING_PROJECT_ID,
  storageBucket: window.FIREBASE_MESSAGING_STORAGE_BUCKET,
  messagingSenderId: window.FIREBASE_MESSAGING_SENDER_ID,
  appId: window.FIREBASE_MESSAGING_APP_ID,
  measurementId: window.FIREBASE_MESSAGING_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: window.VAPID_KEY });
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // Perform any other neccessary action with the token
      localStorage.setItem('notification_token', currentToken);
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
    return currentToken;
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export const onMessageListener = () => {
  onMessage(messaging, (payload) => {
    console.log('Payload:', payload);

    // Display notification using ServiceWorkerRegistration.showNotification()
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
    };

    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(notificationTitle, notificationOptions);
    });
  }, (err) => {
    console.error('Error handling message:', err);
    // Handle error here, e.g., show user-friendly message or log to analytics
  });
};