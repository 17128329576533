import React, { useState } from "react";
import LogoRempah from "../../../assets/images/logo/rempah.png"

import Navigation from "../Navigation";
import ModalError from "../ModalError";

const AdminSignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [messageError, setMessageError] = useState('')

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleLogin = async () => {
    try {
      const data = {
        email: email,
        password: password
      }
      const response = await fetch(`${window.API_HOST}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const responseData = await response.json();

      if (!response.ok) {
        const errorMessage = responseData.error;
        setMessageError(errorMessage);
        throw new Error(errorMessage);
      }

      localStorage.setItem('session_auth', responseData.data?.token);
      window.location.href = window.location.origin + `/admin`;
    } catch (error) {
      if (error instanceof Error) {
        setMessageError(error.message)
        console.error('Standard Error:', error.message);
      } else {
        setMessageError('An error occurred')
        console.error('Unknown Error:', error);
      }
      document.getElementById('submit-error').style.display = 'block';
      document.getElementById('submit-error').style.opacity = 1;
      document.getElementById('backdrop-error').style.display = 'block';
    }
  }

  return (
    <React.Fragment>
      <section>
        <div className="custom-container logo-container mt-5 mb-5">
          <img className="banner-img img-fluid w-50" alt="logo" src={LogoRempah}></img>
          <div className="mt-2 fw-semibold">Admin Login</div>
        </div>
      </section>

      <div className="custom-container" style={{ width: '100%', maxWidth: '600px' }}>
        <form className="auth-form mt-4" target="_blank">
          <div className="form-group">
            <label className="form-label fw-semibold">Admin Email</label>
            <div className="form-input mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Enter email"
                value={email}
                onChange={(e) => handleEmailChange(e)}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label fw-semibold">Password</label>
            <div className="form-input mb-4">
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                value={password}
                onChange={(e) => handlePasswordChange(e)}
              />
            </div>
          </div>
        </form>

        <div
          className="filter-footer"
        >
          <button
            className="btn theme-btn apply-btn w-100"
            style={{
              cursor: email === '' || password === '' ? 'not-allowed' : 'auto',
              pointerEvents: email === '' || password === '' ? 'auto' : 'auto',
              opacity: email === '' || password === '' ? 0.5 : 1
            }}
            onClick={() => handleLogin()}
            disabled={email === '' || password === ''}
          >
            Login
          </button>
        </div>
      </div>

      <ModalError message={messageError} />

      <section className="panel-space"></section>
      <Navigation />
    </React.Fragment>
  )
}

export default AdminSignIn;
