import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import OrderFoodIcon from "../../assets/images/home/orderfood.png"
import ReserveTableIcon from "../../assets/images/home/reservetable.png"
import ReviewUsIcon from "../../assets/images/home/reviewus.png"
import Logo from "./Logo";
import Navigation from "./Navigation";

const Home = () => {
  const googleUrl = window.LINK_REVIEW_US.replace(/&amp;/g, "&");
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${window.API_HOST}/restaurant-status`);
        const result = await response.json();
        setIsOpen(result.data.active);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Logo />
      <section>
        <div className="custom-container">
          <div className="header-panel-lg opening-hours-container">
            <div className="opening-hours-label">Opening Hours</div>
            <div style={{ padding: '8px' }}>
              <div className="date-time-label">Mon-Thu 8:00am - 10:00pm</div>
              <div className="date-time-label">Mon-Thu 8:00am - 11:00pm</div>
            </div>
          </div>
        </div>
      </section>
        
      <section>
        <div className="custom-container">
          <div className="row">
              <div className="col-4">
                { isOpen ? (
                  <div className="main-categories-box">
                    <Link to="/menu">
                        <div className="main-categories-img">
                          <img className="img-fluid img" alt="food" src={OrderFoodIcon}></img>
                        </div>
                        <h6 className="dark-text">Order Food</h6>
                    </Link>
                  </div>
                ) : (
                  <>
                    <div className="main-categories-box" style={{ cursor: 'not-allowed' }}>
                      <div className="main-categories-img">
                        <img className="img-fluid img" alt="food" src={OrderFoodIcon} style={{ opacity: 0.4 }}></img>
                      </div>
                      <h6 className="dark-text" style={{ opacity: 0.5 }}>Order Food</h6>
                    </div>
                    <h6 className="dark-text" style={{ fontStyle: 'italic', color: '#CC0000', textAlign: 'center', paddingTop: '6px' }}>Order Food is not available</h6>
                  </>
                )}
              </div>
              <div className="col-4">
                  <div className="main-categories-box">
                      <a href={window.LINK_RESERVE_TABLE} target="_blank" rel="noopener noreferrer">
                          <div className="main-categories-img">
                            <img className="img-fluid img" alt="reservetable" src={ReserveTableIcon}></img>
                          </div>
                          <h6 className="dark-text">Reserve Table</h6>
                      </a>
                  </div>
              </div>
              <div className="col-4">
                  <div className="main-categories-box">
                      <a href={googleUrl} target="_blank" rel="noopener noreferrer">
                          <div className="main-categories-img">
                            <img className="img-fluid img" alt="reviewus" src={ReviewUsIcon}></img>
                          </div>
                          <h6 className="dark-text">Review Us</h6>
                      </a>
                  </div>
              </div>
          </div>
        </div>
      </section>

      <section className="panel-space"></section>
      <Navigation />
    </React.Fragment>
  )
}

export default Home;
