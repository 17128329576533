import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";

import DotsDesign from "../../../assets/images/svg/dots-design.svg"
import ModalError from '../ModalError';

const OrderDetail = () => {
  const { orderId } = useParams();
  const [sessionToken, setSessionToken] = useState('');
  const [orderData, setOrderData] = useState(null)
  const [messageError, setMessageError] = useState('')

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('session_auth');
    if (!isLoggedIn) {
      window.location.href = window.location.origin + `/admin/login`;
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const storedData = localStorage.getItem('session_auth');
      try {
        if (storedData) {
          setSessionToken(storedData)
          const response = await fetch(`${window.API_HOST}/admin/orders?status[]=pending&status[]=processed&status[]=delivered&status[]=completed&status[]=cancelled`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedData}`
            }
          });
          const result = await response.json();
          const order = result.data.filter(item => {
            return item.order_number.toString() === orderId.toString()
          })
          setOrderData(order[0])
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const comaSeparatedSubMenu = (subMenu) => {
    let allSubMenu = []
    subMenu.forEach(sub => {
      if (sub.quantity === 1) {
        allSubMenu.push(sub.title)
      } else if (sub.quantity > 1) {
        allSubMenu.push(`(${sub.quantity}) ${sub.title}`)
      }
    })
    return allSubMenu.join(', ')
  }

  const updateStateOrderStatus = async (state) => {
    try {
      const response = await fetch(`${window.API_HOST}/admin/orders/${orderId}?status_to=${state}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });

      const responseData = await response.json();

      if (!response.ok) {
        const errorMessage = responseData.error;
        setMessageError(errorMessage);
        throw new Error(errorMessage);
      }
      setOrderData((prev) => ({
        ...prev,
        status: state
      }))
    } catch (error) {
      if (error instanceof Error) {
        setMessageError(error.message)
        console.error('Standard Error:', error.message);
      } else {
        setMessageError('An error occurred')
        console.error('Unknown Error:', error);
      }
      document.getElementById('submit-error').style.display = 'block';
      document.getElementById('submit-error').style.opacity = 1;
      document.getElementById('backdrop-error').style.display = 'block';
    }
  }

  if (!orderData) {
    return <p>Loading...</p>;
  }

  return (
    <React.Fragment>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/orders">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Order Detail</h2>
          </div>
        </div>
      </header>

      <section>
        <div className="custom-container">
          <h5 className="dark-text">
            <span className="light-text">Order ID : </span>{orderId}
          </h5>
        </div>
        <div className="custom-container mt-1">
          <h5 className="dark-text fw-semibold">
            Payment Status : <span className='fw-normal'>
              { orderData.payment_method === 'cash' ? 'Waiting for Payment' :
                (orderData.payment_status ?
                  (orderData.payment_status === 'pending' ? 'Waiting for Payment'
                    : (orderData.payment_status.charAt(0).toUpperCase() + orderData.payment_status.slice(1).toLowerCase())
                  ) : 'Waiting for Payment'
                )
              }
            </span>
          </h5>
        </div>

        <div className="custom-container">
          { orderData && orderData.items && orderData.items.map((menu, index) => (
            <div key={`menu-order-${menu.menu_id}-${index}`} className="horizontal-product-box mt-3">
              <div className="product-img">
                <img
                  src={menu.cover_image}
                  className="img-fluid img"
                  style={{ width: '92px', height: '92px' }}
                  alt="image-food"
                />
              </div>
              <div className="product-content row" style={{ display: 'flex', padding: '8px 8px 16px 8px' }}>
                <div className="col-10">
                  <h5>{menu.title}</h5>
                  <h6>{comaSeparatedSubMenu(menu.sub_menu)}</h6>
                </div>
                <div className="col-2" style={{ alignSelf: 'center', margin: '0px', padding: '0px', marginTop: '16px' }}>
                  <h3 className="product-price" style={{ position: 'relative', display: 'flex', justifyContent: 'right', bottom: '0px' }}>
                    AED {menu.subtotal}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      { orderData && (
        <>
          <section className="delivery address">
            <div className="custom-container">
              <h3 className="fw-semibold dark-text">{orderData.order_type === 'delivery' ? 'Delivery Address' : 'Pickup Info'}</h3>
              <div className="cart-add-box mt-3">
                <div className="add-content" style={{ width: '100%' }}>
                  { orderData.order_type === 'delivery' ? (
                    <>
                      <h5 className="fw-semibold dark-text">Deliver to : {orderData.buyer.name}</h5>
                      <h6 className="address light-text mt-1 w-100">{orderData.delivery.address}</h6>
                    </>
                  ) : (
                    <>
                      <h5 className="fw-semibold dark-text">Pickup by : {orderData.buyer.name}</h5>
                      <h6 className="address light-text mt-1 w-100">{orderData.buyer.phone_number}</h6>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="bill-details section-b-space">
            <div className="custom-container">
              <h3 className="fw-semibold dark-text">Bill Details</h3>
              <div className="total-detail mt-3">
                <div className="sub-total mb-3">
                  <h5>Sub Total</h5>
                  <h5 className="fw-semibold">AED {orderData.item_subtotal}</h5>
                </div>
                { orderData.tax_amount > 0 && (
                  <div className="sub-total mb-3">
                    <h5>VAT ({orderData.tax_percentage}{orderData.tax_included ? ' included' : ''})</h5>
                    <h5 className="fw-semibold">AED {orderData.tax_amount}</h5>
                  </div>
                )}
                { orderData.order_type === 'delivery' && (
                  <div className="sub-total mb-3">
                    <h5>Delivery Charge</h5>
                    <h5 style={{ color: '#FF9933' }}>AED {orderData.delivery_fee}</h5>
                  </div>
                )}
                <div className="grand-total mb-3">
                  <h5 className="fw-semibold">Grand Total</h5>
                  <h5 className="fw-semibold amount">AED {orderData.payment_amount}</h5>
                </div>
                <img
                  className="dots-design"
                  alt="dots"
                  src={DotsDesign}
                ></img>
              </div>
            </div>
          </section>

          <section>
            <div className='custom-container'>
              { orderData.status === 'pending' && (
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                  <div
                    className="btn fw-semibold theme-btn confirm-location-btn w-100"
                    aria-label="reject"
                    style={{ background: '#CC0000' }}
                    onClick={() => {
                      updateStateOrderStatus('cancelled')
                    }}
                  >
                    Reject
                  </div>
                  <div
                    className="btn fw-semibold theme-btn confirm-location-btn w-100"
                    aria-label="confirm"
                    onClick={() => {
                      updateStateOrderStatus('processed')
                    }}
                  >
                    Confirm
                  </div>
                </div>
              )}

              { orderData.status === 'processed' && (
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                  <div
                    className="btn fw-semibold theme-btn confirm-location-btn w-100"
                    aria-label="cancel"
                    style={{ background: '#CC0000' }}
                    onClick={() => {
                      updateStateOrderStatus('cancelled')
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn fw-semibold theme-btn confirm-location-btn w-100"
                    aria-label="food on delivered"
                    onClick={() => {
                      updateStateOrderStatus('delivered')
                    }}
                  >
                    Food on delivery
                  </div>
                </div>
              )}

              { orderData.status === 'delivered' && (
                <div
                  className="btn theme-btn confirm-location-btn w-100"
                  aria-label="complete order"
                  onClick={() => {
                    updateStateOrderStatus('completed')
                  }}
                >
                  Complete Order
                </div>
              )}

              { orderData.status === 'completed' && (
                  <div
                    className="btn theme-btn confirm-location-btn w-100"
                    aria-label="order completed"
                    style={{ cursor: 'default' }}
                  >
                    Order Completed
                  </div>
              )}
              
              { orderData.status === 'cancelled' && (
                  <div
                    className="btn theme-btn confirm-location-btn w-100"
                    aria-label="order rejected"
                    style={{ background: '#CC0000', cursor: 'default' }}
                  >
                    Order Rejected
                  </div>
              )}
            </div>
          </section>
        </>
      )}

      <ModalError message={messageError} />
    </React.Fragment>
  )
}

export default OrderDetail;
