import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MenuCategoryIcon from "../../../assets/images/home/menucategory.png"

const UpdateMenuStatus = () => {
  const [sessionToken, setSessionToken] = useState('');
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('session_auth');
    if (!isLoggedIn) {
      window.location.href = window.location.origin + `/admin/login`;
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const storedData = localStorage.getItem('session_auth');
      try {
        if (storedData) {
          setSessionToken(storedData)
          const response = await fetch(`${window.API_HOST}/admin/menu-categories`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedData}`
            }
          });
          const result = await response.json();
          setCategories(result.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleMenuAvailability = async (menu, idx) => {
    try {
      const response = await fetch(`${window.API_HOST}/admin/menu-categories/${menu.id}?available=${!menu.available}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to update data');
      }
      const result = await response.json();
      setCategories((prev) => {
        const updatedCategories = [...prev]
        updatedCategories[idx].available = result?.data.available
        return updatedCategories
      })
    } catch (error) {
      console.error('Error update status menu category:', error);
    }
  };

  return (
    <React.Fragment>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Update Menu Status</h2>
          </div>
        </div>
      </header>

      <section>
        <div className="custom-container">
          <h3 className="fw-semibold dark-text mt-4">Per Food Category</h3>

          { categories && categories.map((menu, menuIdx) => (
            <div key={`category-${menu.id}-${menuIdx}`} className="tab-content mt-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Link to={`/admin/menu/${menu.id}`}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <img
                    src={MenuCategoryIcon}
                    alt={menu.title}
                    style={{ height: '50px' }}
                  />
                  <h4 className='fw-semibold dark-text'>
                    { menu.title }
                  </h4>
                </div>
              </Link>

              <div>
                <div className='notification-setting remove-box'>
                  <li>
                    <div className="notification p-0">
                      <div className="switch-btn" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <input type="checkbox" checked={menu.available} onChange={() => handleMenuAvailability(menu, menuIdx)} />
                        <h5 className="dark-text">
                          {menu.available ? 'Available' : 'Not Available'}
                        </h5>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </React.Fragment>
  )
}

export default UpdateMenuStatus;
