import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

const AddProductModal = ({ currentMenu, detail, subMenu, onSetCustomFood }) => {
  const [dataSubMenu, setDataSubMenu] = useState([]);
  const { id, title, description, price, cover_image } = detail;
  const [currentMenuData, setCurrentMenuData] = useState({
    menu_id: 0,
    notes: '',
    subtotal: 0,
  });

  useEffect(() => {
    setDataSubMenu(subMenu);
  }, [subMenu]);

  useEffect(() => {
    setCurrentMenuData(currentMenu);
  }, [currentMenu]);

  const handleSubMenuQuantityChange = (value, menuIdx, optionIdx) => {
    setDataSubMenu((prevDataSubMenu) => {
      const updatedDataSubMenu = [...prevDataSubMenu];
      const currentQty = parseInt(updatedDataSubMenu[menuIdx].options[optionIdx].sub_quantity)
      const qtyChanged = currentQty + value
      if (qtyChanged >= 0) {
        updatedDataSubMenu[menuIdx].options[optionIdx].sub_quantity = qtyChanged;
        updatedDataSubMenu[menuIdx].calc_add_on += value;
      }
      return updatedDataSubMenu;
    });
  };

  const handleSubMenuSelectedChange = (menuIdx, optionIdx) => {
    setDataSubMenu((prevDataSubMenu) => {
      const updatedDataSubMenu = [...prevDataSubMenu];
      updatedDataSubMenu[menuIdx].options.forEach((option, idx) => {
        updatedDataSubMenu[menuIdx].options[idx].selected = idx === optionIdx;
        if (idx === optionIdx) {
          updatedDataSubMenu[menuIdx].option_id_selected = option.id
        }
      });
  
      return updatedDataSubMenu;
    });
  };

  const handleNotesChange = (event) => {
    setCurrentMenuData((prev) => ({
      ...prev,
      notes: event.target.value,
    }));
  };

  const calculateGrandTotal = () => {
    let calcAddOn = price
    dataSubMenu.forEach((subMenu) => {
      if (subMenu.multiple_selection) {
        subMenu.options.forEach((item) => {
          calcAddOn += (item.price*item.sub_quantity)
        })
      } else {
        const filterSelected = subMenu.options.filter((item) => item.selected)
        if (filterSelected.length > 0) {
          calcAddOn += (filterSelected[0]?.price ? filterSelected[0]?.price : 0)
        }
      }
    })
    return calcAddOn
  }

  const hasItemWithConditions = dataSubMenu.every(menu => {
    if (!menu.options || menu.options.length === 0) {
      return true;
    }
    if (menu.multiple_selection) {
        return menu.calc_add_on >= menu.min_choosen && menu.calc_add_on <= menu.max_choosen;
    } else {
        return menu.option_id_selected !== -1;
    }
  });

  return (
    <div className="modal filter-modal" id="add-product" tabIndex="-1" style={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}>
      <div className="modal-dialog modal-fullscreen" style={{ width: '100%' }}>
        <div className="modal-content">
          <div className="custom-container">
            <div className="filter-header section-t-space">
              <h1 className="title">Custom Food</h1>
              <a href="#" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
            </div>
            <div className="filter-body section-t-space">
              <div className="horizontal-product-box">
                <div className="product-img">
                  <img
                    src={cover_image}
                    className="img-fluid img"
                    style={{ width: 'auto', height: '98px' }}
                    alt="image-food"
                  />
                </div>
                <div className="product-content row" style={{ display: 'flex', padding: '8px 16px 8px 8px' }}>
                  <div className="col-10">
                    <h5>{title}</h5>
                    <h6>{description}</h6>
                  </div>
                  <div className="col" style={{ alignSelf: 'center', margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <h3 className="product-price" style={{ position: 'relative', display: 'flex', justifyContent: 'right', bottom: '0px' }}>
                      AED {parseFloat(price).toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>

              {dataSubMenu && dataSubMenu.map((menu, menuIdx) => (
                <div key={`menu-${menu.id}`} className="filter-box section-t-space">
                  <h3 className="fw-semibold dark-text">
                    {menu.title}
                    <span
                      style={{
                        fontSize: '12px',
                        fontWeight: 300,
                        fontStyle: 'italic'
                      }}
                    >
                      { menu.multiple_selection ? (` (${menu.min_choosen > 0 ? `min. ${menu.min_choosen} and max. ${menu.max_choosen} for all item)` : `max. ${menu.max_choosen} for all item)`}`) : ''}
                    </span>
                  </h3>

                  <ul className="filter-list my-3">
                    { menu.multiple_selection ? (
                      menu.options.map((option, optionIdx) => (
                        <li
                          key={`menu-multiple-option${option.id}`}
                          style={{ opacity: !option.available ? 0.5 : 1 }}
                        >
                          <h5 className="product-size">{option.title}</h5>
                          <div className="form-check product-price" style={{ paddingRight: 0 }}>
                            <label className="form-check-label" htmlFor="reverseCheck1" style={{ display: "inline-flex", alignItems: "center", marginRight: "8px" }}>
                              {`+ ${option.currency} ${Number(option.price).toFixed(2)}`}
                            </label>
                            <div className="options-custom-food plus-minus">
                              <i
                                className="ri-subtract-line sub"
                                style={{
                                  cursor: !option.available ? 'not-allowed' : 'auto',
                                  pointerEvents: !option.available ? 'auto' : 'auto'
                                }}
                                onClick={!option.available ? null : () => handleSubMenuQuantityChange(-1, menuIdx, optionIdx)}
                              ></i>
                              <input
                                type="number"
                                value={option.sub_quantity}
                                min='0'
                                max={menu.max_choosen}
                                disabled={!option.available}
                                style={{
                                  width: '30px',
                                  cursor: !option.available ? 'not-allowed' : 'auto',
                                  pointerEvents: !option.available ? 'auto' : 'auto'
                                }}
                                onChange={(e) => handleSubMenuQuantityChange(e.target.value, menuIdx, optionIdx)}
                              />
                              <i
                                className="ri-add-line add"
                                style={{
                                  cursor: !option.available ? 'not-allowed' : (menu.calc_add_on < menu.max_choosen ? 'auto' : 'not-allowed'),
                                  pointerEvents: !option.available ? 'auto' : 'auto'
                                }}
                                onClick={!option.available ? null : () => {
                                  if (menu.calc_add_on < menu.max_choosen) {
                                    handleSubMenuQuantityChange(1, menuIdx, optionIdx);
                                  }
                                }}
                              ></i>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      menu.options.map((option, optionIdx) => (
                        <li
                          key={`menu-option${option.id}`}
                          style={{ opacity: !option.available ? 0.5 : 1 }}
                        >
                          <h5 className="product-size">{option.title}</h5>
                          <div className="form-check product-price">
                            <label className="form-check-label" htmlFor={option.id}>
                              {`+ ${option.currency} ${Number(option.price).toFixed(2)}`}
                            </label>
                            <input
                              className="form-check-input"
                              type="radio"
                              value={option.id}
                              id={option.id}
                              checked={option.selected}
                              disabled={!option.available}
                              style={{
                                cursor: !option.available ? 'not-allowed' : 'auto',
                                pointerEvents: !option.available ? 'auto' : 'auto',
                              }}
                              onChange={() => handleSubMenuSelectedChange(menuIdx, optionIdx)}
                            />
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              ))}

              <div className="auth-form body-title d-flex section-t-space section-b-space">
                <div className="form-group" style={{ width: "100%" }}>
                  <div className="form-input">
                    <textarea
                      className="form-control"
                      rows="2"
                      placeholder="Order notes"
                      value={currentMenuData.notes}
                      onChange={(e) => handleNotesChange(e)}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="body-title d-flex justify-content-between section-t-space section-b-space">
                <h3 className="fw-semibold dark-text d-flex align-items-center">Total</h3>
                <h2 className="theme-color">AED {calculateGrandTotal()}</h2>
              </div>
            </div>
            <div className="filter-footer">
              {!hasItemWithConditions ? (
                <button className="btn theme-btn apply-btn w-100" disabled style={{ cursor: 'not-allowed', pointerEvents: 'auto', opacity: 0.5 }}>
                  Add to Cart
                </button>
              ) : (
                <Link
                  to={`/menu/${id}`}
                  className="btn theme-btn apply-btn w-100"
                  data-bs-dismiss="modal"
                  onClick={() => onSetCustomFood(dataSubMenu, detail, currentMenuData)}
                >
                  Add to Cart
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductModal;
