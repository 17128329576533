import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onMessageListener } from '../firebase';
import Home from './Home';
import Menu from './Menu';
import MenuShow from './MenuShow';
import OrderPreview from './OrderPreview';
import OrderSearch from './OrderSearch';
import OrderStatus from './OrderStatus';
import AdminSignIn from './admin/AdminSignIn';
import AdminHome from './admin/AdminHome';
import OrderList from './admin/OrderList';
import OrderDetail from './admin/OrderDetail';
import UpdateMenuStatus from './admin/UpdateMenuStatus';
import UpdateShowMenu from './admin/UpdateShowMenu';

const PageNotFound = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '30px',
    }}>
      <h1>Page Not Found</h1>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    // Listen for incoming messages
    const handleIncomingMessages = async () => {
      try {
        // Foreground messages
        await onMessageListener();
      } catch (error) {
        console.error('Error handling incoming message:', error);
      }
    };

    handleIncomingMessages();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/menu/:id' element={<MenuShow />} />
        <Route path='/menu/order' element={<OrderPreview />} />
        <Route path='/order-status/check' element={<OrderSearch />} />
        <Route path='/order-status/:orderId' element={<OrderStatus />} />
        <Route path='/admin/login' element={<AdminSignIn />} />
        <Route path='/admin' element={<AdminHome />} />
        <Route path='/admin/orders' element={<OrderList />} />
        <Route path='/admin/orders/:orderId' element={<OrderDetail />} />
        <Route path='/admin/menus' element={<UpdateMenuStatus />} />
        <Route path='/admin/menu/:id' element={<UpdateShowMenu />} />

        {/* Catch-all route */}
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
