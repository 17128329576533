import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import AddProductModal from "./AddProductModal";
import ProductDetailPopup from "./ProductDetailPopup";
import CartPopup from "./CartPopup";

const MenuShow = () => {
  const { id } = useParams();
  const [category, setCategory] = useState('');
  const [menu, setMenu] = useState([]);
  const [detailFood, setDetailFood] = useState({})
  const [subMenu, setSubMenu] = useState([])
  const [currentMenu, setCurrentMenu] = useState({})
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${window.API_HOST}/menu-category`);
        const result = await response.json();
        const title = result.data.filter(item => {
          return item.id === parseInt(id)
        })
        setCategory(title[0].title)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await fetch(`${window.API_HOST}/menu?category_id=${id}`);
        const result = await response.json();
        setMenu(result?.data);
      } catch (error) {
        console.error('Error fetching menu:', error);
      }
    };

    fetchMenu();
  }, [id]);

  useEffect(() => {
    const storedData = localStorage.getItem('cartItems');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      let countTotal = 0
      parsedData.forEach((cart) => {
        countTotal += cart.total
      });
      setTotal(countTotal);
      setCartItems(parsedData);
    }
  }, []);

  const handleDetailProduct = (data) => {
    setDetailFood(data)
  }

  const handleAddProduct = async (data) => {
    try {
      const response = await fetch(`${window.API_HOST}/menu/${data.id}`);
      const result = await response.json();
      const mappingData = result.data.sub_menu.map((item) => ({
        ...item,
        options: item.options.map((option) => ({
          ...option,
          sub_quantity: 0,
          selected: false
        })),
        calc_add_on: 0,
        option_id_selected: -1
      }));
      setDetailFood(data)
      setCurrentMenu({
        menu_id: data.id,
        notes: '',
        subtotal: 0,
      })
      setSubMenu(mappingData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleSetCustomFood = (customMenu, detail, currentMenuData) => {
    let dataTotal = total
    const cartMenuItem = customMenu.map(menu => {
      let calcAddOn = detail.price

      const uuid = uuidv4();
      if (menu.multiple_selection) {
        menu.options.forEach((item) => {
          calcAddOn += (item.price*item.sub_quantity)
        })
      } else {
        const filterSelected = menu.options.filter((item) => item.selected)
        if (filterSelected.length > 0) {
          calcAddOn += (filterSelected[0]?.price ? filterSelected[0]?.price : 0)
        }
      }
      dataTotal += calcAddOn
      return {
        cart_item_id: uuid,
        menu_category_id: parseInt(id),
        menu_id: detail.id,
        title: detail.title,
        cover_image: detail.cover_image,
        quantity: 1,
        currency: detail.currency,
        price: detail.price,
        total: calcAddOn,
        notes: currentMenuData.notes,
        sub_menu: menu.options.map(option => ({
          sub_menu_id: option.id,
          title: option.title,
          price: option.price,
          quantity: option.sub_quantity,
          selected: option.selected,
          total: option.price*option.sub_quantity
        }))
      }
    })
    let dataCartItem = []
    if (cartItems.length === 0) {
      dataCartItem = [...cartMenuItem]
      localStorage.setItem('cartItems', JSON.stringify(cartMenuItem));
    } else {
      dataCartItem = [...cartItems, ...cartMenuItem]
      localStorage.setItem('cartItems', JSON.stringify(dataCartItem));
    }

    setCartItems(dataCartItem)
    setTotal(dataTotal)
  }

  if (!menu) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/menu">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>{category}</h2>
          </div>
        </div>
      </header>

      <section>
        <div className="custom-container">
          <div className="accordion-body">
            {menu.map((item) => (
              <div
                key={item.id}
                className="product-box2 mt-4"
                style={{ opacity: !item.available ? 0.5 : 1 }}
              >
                <div className="product-content">
                  <h5 className="product-name">{item.title}</h5>
                  <div className="product-price">
                    <h6 className="fw-semibold">
                      <span>{item.currency} {item.price}</span>
                    </h6>
                  </div>
                  <p className="mb-0 mt-2 pt-2">{item.description}</p>
                </div>
                <div className="product-img" style={{ width: '152px', height: '92px' }}>
                  <Link
                    to="#product-popup"
                    data-bs-toggle="offcanvas"
                  >
                    <img
                      src={item.cover_image}
                      className="img-fluid img"
                      alt={item.title}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      onClick={() => handleDetailProduct(item)}
                    />
                  </Link>
                  <div
                    className="add-btn"
                    onClick={async () => await handleAddProduct(item)}
                  >
                    <button
                      className="btn btn-outline"
                      type="button"
                      data-bs-target="#add-product"
                      data-bs-toggle="modal"
                      disabled={!item.available}
                      style={{
                        cursor: !item.available ? 'not-allowed' : 'auto',
                        pointerEvents: !item.available ? 'auto' : 'auto',
                        opacity: 1,
                      }}
                    >
                      <i className="ri-add-line"></i> ADD
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="panel-space"></section>

      <AddProductModal
        currentMenu={currentMenu}
        detail={detailFood}
        subMenu={subMenu}
        onSetCustomFood={(dataSubMenu, detail, currentMenuData) =>
          handleSetCustomFood(dataSubMenu, detail, currentMenuData)
        }
      />

      <ProductDetailPopup data={detailFood} />

      { cartItems.length > 0 && (
        <CartPopup cartItems={cartItems} totalFee={total} />
      )}
    </>
  );
};

export default MenuShow;
