import React, { useEffect, useState } from "react";

import LogoRempah from "../../assets/images/logo/rempah.png"

import Logo from "./Logo";
import Navigation from "./Navigation";
import MenuCartPopup from "./MenuCartPopup";
import MenuCategoryIcon from "../../assets/images/home/menucategory.png"

const Menu = () => {
  const [categories, setCategories] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${window.API_HOST}/menu-category`);
        const result = await response.json();
        setCategories(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('cartItems');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      let countTotal = 0
      parsedData.forEach((cart) => {
        countTotal += cart.total
      });
      setTotal(countTotal);
      setCartItems(parsedData);
    }
  }, []);

  if (!categories) {
    return <p>Loading...</p>;
  }

  return (
    <React.Fragment>
      <Logo />
      <section>
          <div className="custom-container">
              <div className="row g-3">
                  <div className="col-12">
                      <div className="main-banner-box">
                        <img className="banner-img img-fluid w-100" alt="banner" src={LogoRempah}></img>
                      </div>
                  </div>
              </div>
          </div>
      </section>

    <section className="section-t-space">
      <div className="custom-container">
        <div className="title">
          <h3 className="mt-0">Food Categories</h3>
        </div>

        <section className="categories pt-0">
          <div className="custom-container">
            <div className="row">
              {categories && categories.map((category) => (
                <div
                  key={category.id}
                  className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-6 py-3"
                  style={{
                    pointerEvents: !category.available ? 'none' : 'auto',
                    opacity: !category.available ? 0.5 : 1
                  }}
                >
                  <a href={`/menu/${category.id}`}>
                    <div className="food-categories">
                      <img
                        src={MenuCategoryIcon}
                        alt={category.title}
                        className="img-fluid categories-img"
                      />
                    </div>
                    <h6 style={{ whiteSpace: 'normal', overflow: 'visible'}}>{category.title}</h6>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </section>

    <section className="panel-space"></section>

    { cartItems.length > 0 && (
      <MenuCartPopup cartItems={cartItems} totalFee={total} />
    )}
    <Navigation />
    </React.Fragment>
  )
}

export default Menu;
