import React, { useEffect, useState } from "react";
import { requestForToken } from '../firebase';

import Map from './Map';
import ModalError from "./ModalError";

import DotsDesign from "../../assets/images/svg/dots-design.svg"
import MamoPayIcon from "../../assets/images/icons/svg/mamo-pay.svg"
import CashIcon from '../../assets/images/icons/svg/cash.svg'
import ErrorAddressIcon from '../../assets/images/error_rounded.png'
import ModalNotificationPrompt from "./ModalNotificationPrompt";

const backdropStyles = {
  display: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

const OrderPreview = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');
  const [isAllowed, setIsAllowed] = useState(true);
  const [addressInfo, setAddressInfo] = useState({
    buyer_name: '',
    phone_number: '',
    address: {
      address: '',
      coordinates: { lat: null, lng: null }
    },
    email: ''
  })
  const [tempAddressInfo, setTempAddressInfo] = useState({
    buyer_name: '',
    phone_number: '',
    address: {
      address: '',
      coordinates: { lat: null, lng: null }
    },
    email: ''
  })
  const [notesDelivery, setNotesDelivery] = useState('')

  const [isOpenChangeAddress, setIsOpenChangeAddress] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [changedCartItems, setChangedCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalAfterTax, setTotalAfterTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [deliveryCharge, setDeliveryCharge] = useState(null);
  const [taxAmount, setTaxAmount] = useState(null)
  const [taxIncluded, setTaxIncluded] = useState(null)
  const [taxPercentage, setTaxPercentage] = useState(null);

  const [notifToken, setNotifToken] = useState('')
  const [messageError, setMessageError] = useState('')

  const checkNotif = async () => {
    const storedData = localStorage.getItem('notification_token');
    if (storedData) {
      setNotifToken(storedData)
    } else {
      if ('Notification' in window) {
        console.log('Notification', Notification.permission);
        if (Notification.permission === 'granted') {
          await requestForToken();
        } else if (Notification.permission !== 'denied') {
          setIsAllowed(false);
        }
      } else {
        const installPromptShown = localStorage.getItem('installPromptShown');
        if (!installPromptShown) {
          alert('Notifications are not supported in this browser\n\nPlease add Rempah Web App to your home screen');
          localStorage.setItem('installPromptShown', 'true');
        }
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      const storedData = localStorage.getItem('cartItems');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        let countTotal = 0
        parsedData.forEach((cart) => {
          countTotal += cart.total
        });
        setTotal(countTotal);
        const taxInfo = await getTaxInfo(countTotal);
        setTaxAmount(taxInfo.tax_amount)
        setTaxIncluded(taxInfo.tax_included)
        setTaxPercentage(taxInfo.tax_percentage)
        setTotalAfterTax(taxInfo.final_amount)
        setGrandTotal(taxInfo.final_amount);
        setChangedCartItems(parsedData);
      } else {
        window.location.href = window.location.origin
      }
    }
    fetchData()
  }, []);

  useEffect(() => {
    checkNotif();
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handlePaymentChange = (e) => {
    setSelectedPayment(e.target.value);
  };

  const handleInputChange = (e, field) => {
    setTempAddressInfo((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleNotes = (e) => {
    setNotesDelivery(e.target.value)
  }

  const comaSeparatedSubMenu = (subMenu) => {
    let allSubMenu = []
    subMenu.forEach(sub => {
      if (sub.quantity === 0 && sub.selected) {
        allSubMenu.push(sub.title)
      } else if (!sub.selected && sub.quantity > 0) {
        allSubMenu.push(`(${sub.quantity}) ${sub.title}`)
      }
    })
    return allSubMenu.join(', ')
  }

  const handleLocationConfirmation = (address, coordinates) => {
    setSelectedLocation({ address, coordinates });
    setTempAddressInfo((prev) => ({
      ...prev,
      address: {
        address: address,
        coordinates: coordinates
      },
    }));
  };

  const handleMenuQuantityChange = async (value, menuIdx) => {
    let updatedCartItem = [...changedCartItems]
    const currentFeePerItem = updatedCartItem[menuIdx].total / updatedCartItem[menuIdx].quantity
    const currentQtyOnItem = updatedCartItem[menuIdx].quantity
    const qtyChanged = currentQtyOnItem + value
    if (qtyChanged > 0) {
      updatedCartItem[menuIdx].quantity = qtyChanged
      updatedCartItem[menuIdx].total = qtyChanged * currentFeePerItem
    } else {
      updatedCartItem.splice(menuIdx, 1);
    }

    setChangedCartItems(updatedCartItem)
    let countTotal = 0
    updatedCartItem.forEach((cart) => {
      countTotal += cart.total
    });
    setTotal(countTotal)
    const taxInfo = await getTaxInfo(countTotal);
    setTaxAmount(taxInfo.tax_amount)
    setTaxIncluded(taxInfo.tax_included)
    setTaxPercentage(taxInfo.tax_percentage)
    setTotalAfterTax(taxInfo.final_amount)
    setGrandTotal(taxInfo.final_amount + parseFloat(deliveryCharge?.delivery_fee || 0));
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItem));
  }

  const handleSetAddress = async () => {
    try {
      const response = await fetch(`${window.API_HOST}/v1/orders/delivery?lat=${tempAddressInfo.address.coordinates.lat}&long=${tempAddressInfo.address.coordinates.lng}`);
      const result = await response.json();
      if (result.message || result.error) {
        document.getElementById('error-set-address').style.display = 'block';
        document.getElementById('error-set-address').style.opacity = 1;
        document.getElementById('backdrop').style.display = 'block';
      } else {
        setDeliveryCharge(result.data)
        setGrandTotal(totalAfterTax + parseFloat(result.data.delivery_fee))
        setAddressInfo(tempAddressInfo)
        setIsOpenChangeAddress(false)
      }
    } catch (error) {
      console.error('Error get delivery fee:', error);
    }
  }

  const handleSetPickupInfo = async () => {
    setAddressInfo(tempAddressInfo)
    setIsOpenChangeAddress(false)
  }

  const handlePayNowOrder = async () => {
    let data = {
      order_type: selectedOption,
      order_notes: notesDelivery,
      buyer: {
        name: addressInfo.buyer_name,
        phone_number: addressInfo.phone_number,
        email: addressInfo.email
      },
      payment_method: selectedPayment,
      notification_token: notifToken === '' ? localStorage.getItem('notification_token') : notifToken,
      items: changedCartItems.map(cart => {
        return {
          menu_id: cart.menu_id,
          quantity: cart.quantity,
          notes: cart.notes,
          sub_menu: cart.sub_menu.map(sub => {
            return {
              sub_menu_id: sub.sub_menu_id,
              quantity: sub.selected && sub.quantity === 0 ? 1 : sub.quantity
            }
          })
        }
      })
    }
    if (selectedOption === 'delivery') {
      data['delivery'] = {
        address: addressInfo.address.address,
        lat: addressInfo.address.coordinates.lat,
        long: addressInfo.address.coordinates.lng
      }
    }    

    try {
      const response = await fetch(`${window.API_HOST}/v1/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      const responseData = await response.json();

      if (!response.ok) {
        const errorMessage = responseData.error;
        setMessageError(errorMessage);
        throw new Error(errorMessage);
      }
  
      localStorage.removeItem('cartItems');
      setChangedCartItems([])
      window.location.href = window.location.origin + `/order-status/${responseData.data.order_number}`;
    } catch (error) {
      if (error instanceof Error) {
        setMessageError(error.message)
        console.error('Standard Error:', error.message);
      } else {
        setMessageError('An error occurred')
        console.error('Unknown Error:', error);
      }
      document.getElementById('submit-error').style.display = 'block';
      document.getElementById('submit-error').style.opacity = 1;
      document.getElementById('backdrop-error').style.display = 'block';
    }
  }

  const resetTempAddressInfo = () => {
    setTempAddressInfo(addressInfo)
  }

  const getTaxInfo = async (subtotal) => {
    const response = await fetch(`${window.API_HOST}/v1/orders/tax-info?subtotal=${subtotal}`);
    const result = await response.json();
    return response.ok ? result.data : null
  }

  const isDisabledSetAddress =
    tempAddressInfo.buyer_name.length === 0 ||
    tempAddressInfo.email.length === 0 ||
    tempAddressInfo.phone_number.length === 0 ||
    tempAddressInfo.address.address.length === 0

  const isDisabledPickupInfo =
    tempAddressInfo.buyer_name.length === 0 ||
    tempAddressInfo.email.length === 0 ||
    tempAddressInfo.phone_number.length === 0
  
  const isDisabledPayNow =
    selectedOption === '' ||
    selectedPayment == '' ||
    addressInfo.phone_number === '' ||
    (selectedOption === 'delivery' && addressInfo.address.address === '')

  return (
    <>
      { isOpenChangeAddress ? (
        <>
          <div id="change-address" className="custom-container" style={{ width: '100%', maxWidth: '600px' }}>
            <div className="filter-header section-t-space" style={{ display: 'flex', width: '100%' }}>
              <h1
                className="title"
                style={{ display: 'flex', justifyContent: 'center', marginBottom: '0' }}
              >{selectedOption === 'delivery' ? 'Complete Address' : 'Pickup Information'}</h1>
              <button
                className="btn-close"
                aria-label="Close"
                style={{ position: 'absolute', top: '20px', boxShadow: 'none' }}
                onClick={() => {
                  setIsOpenChangeAddress(false)
                  resetTempAddressInfo()
                }}
              ></button>
            </div>
            <form className="auth-form mt-4" target="_blank">
              <div className="form-group">
                <label className="form-label fw-semibold">Full Name*</label>
                <div className="form-input mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter full name"
                    value={tempAddressInfo.buyer_name}
                    onChange={(e) => handleInputChange(e, 'buyer_name')}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Phone Number*</label>
                <div className="form-input mb-4">
                  <input
                    type="tel"
                    inputMode="tel"
                    pattern="[0-9+]*"
                    className="form-control"
                    placeholder="Enter phone number"
                    value={tempAddressInfo.phone_number}
                    onChange={(e) => handleInputChange(e, 'phone_number')}
                  />
                </div>
              </div>
              { selectedOption === 'delivery' && (
                <div className="form-group">
                  <label className="form-label fw-semibold">Address*</label>
                  <div className="form-input mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter address"
                      value={selectedLocation ? selectedLocation.address : ''}
                      onClick={() => {
                        document.getElementById('confirm-location').style.display = 'block';
                        document.getElementById('change-address').style.display = 'none'
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="form-group">
                <label className="form-label fw-semibold">E-mail*</label>
                <div className="form-input mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    value={tempAddressInfo.email}
                    onChange={(e) => handleInputChange(e, 'email')}
                  />
                </div>
              </div>
              <p style={{ fontStyle: 'italic' }}>*required</p>
            </form>
            { selectedOption === 'delivery' ? (
              <div
                className="filter-footer"
              >
                <button
                  className="btn theme-btn apply-btn w-100"
                  style={{
                    cursor: isDisabledSetAddress ? 'not-allowed' : 'auto',
                    pointerEvents: isDisabledSetAddress ? 'auto' : 'auto',
                    opacity: isDisabledSetAddress ? 0.5 : 1
                  }}
                  onClick={() => handleSetAddress()}
                  disabled={isDisabledSetAddress}
                >
                  Set Address
                </button>
              </div>
            ) : (
              <div
                className="filter-footer"
              >
                <button
                  className="btn theme-btn apply-btn w-100"
                  style={{
                    cursor: isDisabledPickupInfo ? 'not-allowed' : 'auto',
                    pointerEvents: isDisabledPickupInfo ? 'auto' : 'auto',
                    opacity: isDisabledPickupInfo ? 0.5 : 1
                  }}
                  onClick={() => handleSetPickupInfo()}
                  disabled={isDisabledPickupInfo}
                >
                  Set Information
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <header className="section-t-space">
            <div className="custom-container">
              <div className="header-panel">
                <a href={`/menu`}>
                  <i className="ri-arrow-left-s-line"></i>
                </a>
                <h2>Order Confirmation</h2>
              </div>
            </div>
          </header>

          <section>
            <div className="custom-container">
              <h3 className="fw-semibold dark-text">Food Order</h3>
              { changedCartItems.length > 0 && changedCartItems.map((menu, menuIdx) => (
                <div key={`menu-order-${menu.cart_item_id}`} className="horizontal-product-box mt-3">
                  <div className="product-img">
                    <img
                      src={menu.cover_image}
                      className="img-fluid img"
                      style={{ width: '92px', height: '92px' }}
                      alt="image-food"
                    />
                  </div>
                  <div className="product-content row" style={{ display: 'flex', padding: '8px 8px 16px 8px' }}>
                    <div className="col-10">
                      <h5>{menu.title}</h5>
                      <h6>{comaSeparatedSubMenu(menu.sub_menu)}</h6>
                      <div style={{ display: 'flex' }}>
                        <div className="options-custom-food plus-minus" style={{ display: 'flex', position: 'relative', top: '10px', left: '42px' }}>
                          <i className="ri-subtract-line sub" onClick={() => handleMenuQuantityChange(-1, menuIdx)}></i>
                          <input type="number" style={{width: '30px'}} value={menu.quantity} onChange={(e) => handleMenuQuantityChange(e.target.value, menuIdx)} />
                          <i
                            className="ri-add-line add"
                            onClick={() => handleMenuQuantityChange(1, menuIdx)}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-2" style={{ alignSelf: 'center', margin: '0px', padding: '0px', marginTop: '16px' }}>
                      <h3 className="product-price" style={{ position: 'relative', display: 'flex', justifyContent: 'right', bottom: '0px' }}>
                        {menu.currency} {menu.total}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="section-b-space">
            <div className="custom-container mt-3">
              <div className="accordion option-accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                    >
                      { selectedOption.length === 0 ? (
                        <>Select Type of Order</>
                      ) : (
                        <>Type of Order: {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1).toLowerCase()}</>
                      )}
                      
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse show">
                    <div className="accordion-body">
                      <label className="form-check" htmlFor="radio1">
                        <span className="form-check-label">Delivery</span>
                        <input
                          className="form-check-input"
                          type="radio"
                          id="radio1"
                          value="delivery"
                          checked={selectedOption === "delivery"}
                          onChange={handleOptionChange}
                        />
                      </label>
                      <label className="form-check" htmlFor="radio2">
                        <span className="form-check-label">Pickup</span>
                        <input
                          className="form-check-input"
                          type="radio"
                          id="radio2"
                          value="pickup"
                          checked={selectedOption === "pickup"}
                          onChange={handleOptionChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          { selectedOption !== '' && (
            <section className="delivery address mb-4">
              <div className="custom-container">
                <h3 className="fw-semibold dark-text">{selectedOption === 'delivery' ? 'Delivery Address' : 'Pickup Info'}</h3>
                <div className="cart-add-box mt-3 mb-3">
                  <div className="add-content" style={{ width: '100%' }}>
                    { addressInfo.phone_number === '' ? (
                      <>
                        <h5 className="fw-semibold dark-text">Add your {selectedOption === 'delivery' ? 'delivery address' : 'info'} to place order</h5>
                        <h6 className="address light-text mt-1">Only for {selectedOption === 'delivery' ? 'delivery' : 'pickup'} order</h6>
                      </>
                    ) : (
                      selectedOption === 'delivery' ? (
                        <>
                          <h5 className="fw-semibold dark-text">Deliver to : {addressInfo.buyer_name} Place</h5>
                          <h6 className="address light-text mt-1">{addressInfo.address.address}</h6>
                        </>
                      ) : (
                        <>
                          <h5 className="fw-semibold dark-text">Pickup by : {addressInfo.buyer_name}</h5>
                          <h6 className="address light-text mt-1">{addressInfo.phone_number}</h6>
                        </>
                      )
                    )}
                      <button
                        className="change-add"
                        style={{ border: 'none', background: 'none', fontWeight: (selectedOption === 'delivery' && addressInfo.address.address === '') || (addressInfo.phone_number === '') ? '700' : '500' }}
                        onClick={() => {
                          setTempAddressInfo(addressInfo)
                          setSelectedLocation({ address: addressInfo.address.address, coordinates: addressInfo.address.coordinates })
                          setIsOpenChangeAddress(true)
                        }}
                      >
                        {(selectedOption === 'delivery' && addressInfo.address.address === '') || (addressInfo.phone_number === '') ?
                          'Add'
                        :
                          'Change'
                        }
                      </button>
                  </div>
                </div>
              </div>
            </section>
          )}

          { selectedOption !== '' && (
            <div className="custom-container">
              <h3 className="fw-semibold dark-text mb-3">
                {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1).toLowerCase()} Notes
              </h3>
              <div className="auth-form body-title d-flex">
                <div className="form-group" style={{ width: "100%" }}>
                  <div className="form-input">
                    <textarea
                      className="form-control"
                      rows="2"
                      placeholder="e.g: Deliver at 1 PM"
                      value={notesDelivery}
                      onChange={(e) => handleNotes(e)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          )}

          <section className="bill-details section-b-space">
            <div className="custom-container">
              <h3 className="fw-semibold dark-text">Bill Details</h3>
              <div className="total-detail mt-3">
                <div className="sub-total mb-3">
                  <h5>Sub Total</h5>
                  <h5 className="fw-semibold">AED {parseFloat(total).toFixed(2)}</h5>
                </div>
                { taxAmount > 0 && (
                  <div className="sub-total mb-3">
                    <h5>VAT ({taxPercentage}{taxIncluded ? ' included' : ''})</h5>
                    <h5 className="fw-semibold">AED {parseFloat(taxAmount).toFixed(2)}</h5>
                  </div>
                )}
                { addressInfo.address.address !== '' && (
                  <div className="sub-total mb-3">
                    <h5>Delivery Charge</h5>
                    <h5 style={{ color: '#FF9933' }}>AED {deliveryCharge.delivery_fee}</h5>
                  </div>
                )}
                <div className="grand-total mb-3">
                  <h5 className="fw-semibold">Grand Total</h5>
                  <h5 className="fw-semibold amount">AED {parseFloat(grandTotal).toFixed(2)}</h5>
                </div>
                <img
                  className="dots-design"
                  alt="dots"
                  src={DotsDesign}
                ></img>
              </div>
            </div>
          </section>

          <section className="payment method section-lg-b-space" style={{ paddingBottom: '120px' }}>
            <div className="custom-container">
              <h3 className="fw-semibold dark-text">Payment Method</h3>
              <div className="payment-list">
                <ul className="cart-add-box payment-card-box gap-0 mt-3">
                  <li className="w-100">
                    <label htmlFor="payment_link" style={{ display: 'flex' }}>
                      <div className="payment-detail">
                        <div className="add-img">
                          <img className="img-fluid img" src={MamoPayIcon} alt="payment_link" />
                        </div>
                        <div className="add-content">
                          <div>
                            <h5 className="fw-semibold dark-text">Payment Link</h5>
                            <h6 className="mt-1 light-text">via Mamo Pay</h6>
                          </div>
                          <div className="form-check">
                            <input
                              id="payment_link"
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value="payment_link"
                              checked={selectedPayment === "payment_link"}
                              onChange={handlePaymentChange}
                            />
                          </div>
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
                <ul className="cart-add-box payment-card-box gap-0 mt-3">
                  <li className="w-100">
                    <label htmlFor="cash" style={{ display: 'flex' }}>
                      <div className="payment-detail">
                        <div className="add-img">
                          <img className="img-fluid img" src={CashIcon} alt="cash" />
                        </div>
                        <div className="add-content">
                          <div>
                            <h5 className="fw-semibold dark-text">Cash on Delivery/Cashier</h5>
                          </div>
                          <div className="form-check">
                            <input
                              id="cash"
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value="cash"
                              checked={selectedPayment === "cash"}
                              onChange={handlePaymentChange}
                            />
                          </div>
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          
          {
            changedCartItems.length > 0 && (
              <div className="pay-popup">
                <div className="price-items">
                  <h3>AED {parseFloat(total).toFixed(2)}</h3>
                  <h6>{changedCartItems.length} item Added</h6>
                </div>
                <button
                  className="btn theme-btn pay-btn mt-0"
                  style={{
                    cursor: isDisabledPayNow ? 'not-allowed' : 'auto',
                    pointerEvents: isDisabledPayNow ? 'auto' : 'auto',
                    background: isDisabledPayNow ? '#EBEBEB' : 'rgba(255, 141, 47, 1)',
                    color: isDisabledPayNow ? '#090909' : '#ffffff'
                  }}
                  disabled={isDisabledPayNow}
                  onClick={handlePayNowOrder}
                >
                  Order Now
                </button>
              </div>
            )
          }
        </>
      )}

      { isOpenChangeAddress && selectedOption === 'delivery' && (
        <div id="confirm-location" style={{ display: 'none' }}>
          <Map onLocationConfirm={(addr, coor) => handleLocationConfirmation(addr, coor)} />
        </div>
      )}

      <div>
        <div
          id="backdrop"
          style={backdropStyles}
        />
        <div className="modal fade success-modal" id="error-set-address" tabIndex="-1" style={{ display: 'none', opacity: 1 }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="confirm-title" style={{ textAlign: 'center', padding: '56px 40px 0px 40px' }}>
                  <img className="img-fluid confirm-offer w-50" src={ErrorAddressIcon} alt="error-address" />
                  <div
                    className="mt-5"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '18px'
                    }}
                  >
                    Address is out of our delivery service
                  </div>
                </div>

                <div
                  className="filter-footer mb-3"
                >
                  <button
                    className="btn theme-btn apply-btn w-100"
                    onClick={() => {
                      document.getElementById('error-set-address').style.display = 'none';
                      document.getElementById('backdrop').style.display = 'none';
                    }}
                    disabled={isDisabledSetAddress}
                  >
                    Back to set address
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalError message={messageError} />

      <ModalNotificationPrompt notificationAllowed={isAllowed} />
    </>
  );
};

export default OrderPreview;
