import React, { useState } from "react";
import LogoRempah from "../../assets/images/logo/rempah.png"

const Logo = () => {
  const [clickCount, setClickCount] = useState(0);

  const incrementClickCount = () => {
    setClickCount(clickCount+1);
    if (clickCount > 3) {
      setClickCount(0)
      window.location.href = window.location.origin + `/admin`;
    }
  };

  return (
    <React.Fragment>
      <section>
        <div className="custom-container logo-container">
          <img className="banner-img img-fluid w-25" alt="logo" src={LogoRempah} onClick={incrementClickCount}></img>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Logo
