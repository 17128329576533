import React from "react";
import { Link } from "react-router-dom";

const CartPopup = ({ cartItems, totalFee }) => {
  return (
    <div className="cart-popup">
      <div className="price-items">
        <h3>AED {parseFloat(totalFee).toFixed(2)}</h3>
        <h6>{cartItems.length} item Added</h6>
      </div>
      <Link to={`/menu/order`} className="btn theme-btn cart-btn mt-0">View Order</Link>
    </div>
  );
};

export default CartPopup;
