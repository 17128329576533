import React from "react";

const ProductDetailPopup = ({ data }) => {
  const { title, description, price, cover_image } = data;

  return (
    <div className="offcanvas fade offcanvas-bottom product-detail-popup" tabIndex="-1" id="product-popup" style={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}>
      <div className="product-box-modal ratio_40">
        <img src={cover_image} className="bg-img" alt="detail-food" style={{ width: '100%', display: 'block !important' }} />
        <div className="product-content">
          <div className="product-info">
            <h5 className="product-name">{title}</h5>
            <div className="product-price">
              <h6 className="fw-semibold"><span>${parseFloat(price).toFixed(2)}</span></h6>
            </div>
          </div>
        </div>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ProductDetailPopup;
