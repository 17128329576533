import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng  } from 'react-places-autocomplete';
import { Link } from "react-router-dom";

const Map = ({ onLocationConfirm }) => {
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  useEffect(() => {
    // Mendapatkan lokasi saat ini menggunakan API Geolocation browser
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ lat: latitude, lng: longitude });

          try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${window.GOOGLE_MAPS_API_KEY}`);
            const data = await response.json();
      
            if (data.status === 'OK') {
              setAddress(data.results[0].formatted_address);
            } else {
              console.error('Error getting address:', data.status);
            }
          } catch (error) {
            console.error('Error getting address:', error);
          }
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  const handleMapClick = async (event) => {
    setCoordinates({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=${window.GOOGLE_MAPS_API_KEY}`);
      const data = await response.json();

      if (data.status === 'OK') {
        setAddress(data.results[0].formatted_address);
      } else {
        console.error('Error getting address:', data.status);
      }
    } catch (error) {
      console.error('Error getting address:', error);
    }
  };

  return (
    <LoadScript 
      googleMapsApiKey={window.GOOGLE_MAPS_API_KEY}
      libraries={['places']}
    >
      <GoogleMap
        id="example-map"
        mapContainerStyle={{
          height: '90vh',
          width: '100%',
        }}
        zoom={15}
        center={{ lat: coordinates.lat || 0, lng: coordinates.lng || 0 }}
        onClick={(e) => handleMapClick(e)}
      >
        {coordinates.lat !== null && (
          <Marker position={{ lat: coordinates.lat, lng: coordinates.lng }} />
        )}
      </GoogleMap>

      <div className="location-popup">
        <div
          className="form-group"
        >
          <div className="form-input mt-4 mb-4">
            <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input
                    {...getInputProps({ placeholder: 'Search Places...' })}
                    style={{ width: '100%' }}
                    className="form-control"
                  />
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 1,
                      width: '560px'
                    }}
                  >
                    {loading ? <div>Loading...</div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? '#1a73e8' : '#fff',
                      };
                      return <div {...getSuggestionItemProps(suggestion, { style })}>{suggestion.description}</div>;
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>
        <div className="change-location">
          <div className="d-flex align-items-center gap-2">
            <i className="ri-map-pin-line"></i>
            <h2>{address}</h2>
          </div>
        </div>
        <Link
          href="#"
          className="btn theme-btn confirm-location-btn w-100"
          aria-label="Confirm Location"
          onClick={() => {
            document.getElementById('change-address').style.display = 'block';
            document.getElementById('confirm-location').style.display = 'none';
            onLocationConfirm(address, coordinates);
          }}
        >
          Confirm Location
        </Link>
      </div>
    </LoadScript>
  );
};

export default Map;
