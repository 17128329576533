import React, { useEffect, useState } from 'react';
import { requestForToken } from '../../firebase';
import ModalNotificationPrompt from "../ModalNotificationPrompt";

const AdminHome = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const [sessionToken, setSessionToken] = useState('');

  const registerDevice = async (notifToken) => {
    const authToken = localStorage.getItem('session_auth');
    try {
      if (authToken && notifToken) {
        setSessionToken(authToken)
        const data = {
          registration_token: notifToken,
          device_type: 'web'
        }
        const response = await fetch(`${window.API_HOST}/device`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        await response.json();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const checkNotif = async () => {
    var notifToken = localStorage.getItem('notification_token');
    if (notifToken) {
      registerDevice(notifToken);
    } else {
      if ('Notification' in window) {
        console.log('Notification', Notification.permission);
        if (Notification.permission === 'granted') {
          notifToken = await requestForToken();
          registerDevice(notifToken);
        } else if (Notification.permission !== 'denied') {
          setIsAllowed(false);
        }
      } else {
        const installPromptShown = localStorage.getItem('installPromptShown');
        if (!installPromptShown) {
          alert('Notifications are not supported in this browser\n\nPlease add Rempah Web App to your home screen');
          localStorage.setItem('installPromptShown', 'true');
        }
      }
    }
  };

  const fetchData = async () => {
    const storedData = localStorage.getItem('session_auth');
    try {
      if (storedData) {
        setSessionToken(storedData)
        const response = await fetch(`${window.API_HOST}/admin/constants/restaurant-open`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${storedData}`
          }
        });
        const result = await response.json();
        setIsChecked(result.data.active);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = async () => {
    try {
      const response = await fetch(`${window.API_HOST}/admin/constants/restaurant-open?active=${!isChecked}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });
      const result = await response.json();
      setIsChecked(result.data.active);
    } catch (error) {
      console.error('Error update shop status:', error);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('session_auth');
    if (!isLoggedIn) {
      window.location.href = window.location.origin + `/admin/login`;
    } else {
      checkNotif();
      fetchData();
    }
  }, []);

  return (
    <React.Fragment>
      <header className="section-t-space">
        <div className="custom-container">
          <div style={{ textAlign: 'center' }}>
            <h2>Admin Menu</h2>
          </div>
        </div>
      </header>

      <section>
        <div className="custom-container">
          <div className='notification-setting remove-box'>
            <li>
              <div className="notification pt-3 pb-0 px-3">
                <h5 className="fw-semibold dark-text">Shop Status</h5>
                <div className="switch-btn" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <input type="checkbox" checked={isChecked} onChange={handleChange} />
                  <h6 className="dark-text">
                    {isChecked ? 'Open' : 'Closed'}
                  </h6>
                </div>
              </div>
            </li>
          </div>
        </div>
      </section>

      <section>
        <div className="custom-container">
          <a href={`/admin/orders`}>
            <div className='notification-setting mb-3 py-4'>
              <div className="notification pt-0">
                <h5 className="fw-semibold dark-text">Order List</h5>
              </div>
            </div>
          </a>

          <a href={`/admin/menus`}>
            <div className='notification-setting mb-3 py-4'>
              <div className="notification pt-0">
                <h5 className="fw-semibold dark-text">Update Menu Status</h5>
              </div>
            </div>
          </a>
        </div>
      </section>

      <ModalNotificationPrompt notificationAllowed={isAllowed} callback={registerDevice} />
    </React.Fragment>
  )
}

export default AdminHome;
